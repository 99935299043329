import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    whiteSpace: "nowrap",
    width: drawerWidth,
    paddingBottom: theme.spacing(4),
    color: "#fff",
    backgroundColor: "#05264c",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
  },
  logo: {
    maxHeight: 75,
    borderRadius: 5,
    padding: 5,
    boxSizing: "border-box",
    marginTop: 10,
  },
}));

const drawerWidth = "240px";

export default useStyles;
