import { useFormContext, Controller } from "react-hook-form";

import { TextField as MuiTextField } from "@mui/material";

const TextField = ({
  size = "small",
  variant = "outlined",
  name,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiTextField
          {...props}
          {...field}
          error={!!fieldState.error}
          helperText={fieldState.error && fieldState.error.message}
          size={size}
          variant={variant}
          autoComplete="off"
        />
      )}
    />
  );
};

export default TextField;
