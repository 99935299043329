import { useFormContext, Controller } from "react-hook-form";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = ({ name, ...props }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            {...props}
            control={
              <MuiCheckbox
                name={field.name}
                checked={field.value}
                onBlur={field.onBlur}
                onChange={(e) => field.onChange(e.target.checked)}
                color="primary"
              />
            }
          />
        );
      }}
    />
  );
};

export default Checkbox;
