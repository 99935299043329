import { useState } from "react";
import { NavLink } from "react-router-dom";

import {
  Card,
  Grid,
  Typography,
  CardMedia,
  CardContent,
  ButtonBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 220,
    width: 220,
    margin: 30,
    borderRadius: "5%",
  },
  media: {
    paddingLeft: 55,
    paddingRight: 55,
    paddingTop: 25,
    paddingBottom: 20,
    boxSizing: "border-box",
  },
  label: {
    color: "#555",
  },
}));

const ModuleCard = (props) => {
  const [image, setImage] = useState(props.imageLink);
  const [elevation, setElevation] = useState(5);
  const [label, setLabel] = useState(props.label);

  const classes = useStyles();

  const setHoverImage = () => {
    setImage(props.hover_img);
    setElevation(24);
    setLabel(<strong>{props.label}</strong>);
  };

  const setNormalImage = () => {
    setImage(props.imageLink);
    setElevation(5);
    setLabel(props.label);
  };

  return (
    <Grid item>
      <Card
        className={classes.root}
        onMouseEnter={setHoverImage}
        onMouseLeave={setNormalImage}
        elevation={elevation}
      >
        <ButtonBase>
          <NavLink
            style={{ textDecoration: "inherit", color: "inherit" }}
            to={props.to}
          >
            <CardMedia
              className={classes.media}
              component="img"
              image={image}
            />
            <CardContent>
              <Typography
                className={classes.label}
                align="center"
                variant="overline"
                component="h2"
              >
                {label}
              </Typography>
            </CardContent>
          </NavLink>
        </ButtonBase>
      </Card>
    </Grid>
  );
};

export default ModuleCard;
