import { connect } from "react-redux";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ModuleCard from "./ModuleCard/ModuleCard";

import hr_img from "../../assets/images/Homepage/hr.png";
import hr_img_hover from "../../assets/images/Homepage/hr_hover.png";
import inventory_img from "../../assets/images/Homepage/inventory.png";
import inventory_img_hover from "../../assets/images/Homepage/inventory_hover.png";
import company_setup_img from "../../assets/images/Homepage/company_setup.png";
import company_setup_img_hover from "../../assets/images/Homepage/company_setup_hover.png";
import chatbot_img from "../../assets/images/Homepage/chatbot.png";
import chatbot_img_hover from "../../assets/images/Homepage/chatbot_hover.png";
import invoice_img from "../../assets/images/Homepage/invoice.png";
import invoice_img_hover from "../../assets/images/Homepage/invoice_hover.png";
import permission_img from "../../assets/images/Homepage/permission.png";
import permission_img_hover from "../../assets/images/Homepage/permission_hover.png";
import blog_img from "../../assets/images/Homepage/blog.png";
import blog_img_hover from "../../assets/images/Homepage/blog_hover.png";

const cardData = {
  hr: {
    label: "Human Resources",
    to: "/hr",
    imageLink: hr_img,
    hover_img: hr_img_hover,
    module_name: "hr",
  },
  inventory: {
    label: "Inventory",
    to: "/inventory",
    imageLink: inventory_img,
    hover_img: inventory_img_hover,
    module_name: "inventory",
  },
  company_setup: {
    label: "Company Setup",
    to: "/company-setup",
    imageLink: company_setup_img,
    hover_img: company_setup_img_hover,
    module_name: "company_setup",
  },
  chatbot: {
    label: "Chatbot",
    to: "/chatbot",
    imageLink: chatbot_img,
    hover_img: chatbot_img_hover,
    module_name: "chatbot",
  },
  invoice_tracking: {
    label: "Invoice Tracking",
    to: "/invoice-tracking",
    imageLink: invoice_img,
    hover_img: invoice_img_hover,
    module_name: "invoice_tracking",
  },
  blog: {
    label: "Blog",
    to: "/blog",
    imageLink: blog_img,
    hover_img: blog_img_hover,
    module_name: "blog",
  },
  oauth2: {
    label: "Permission Manager",
    to: "/permission-manager",
    imageLink: permission_img,
    hover_img: permission_img_hover,
    module_name: "oauth2",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    height: "calc(100vh - 84px)",
    width: "100vw",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 64px)",
    },
  },
}));

const ModuleCards = (props) => {
  const classes = useStyles();

  const cards = [];

  props.modules.forEach((module) => {
    if (cardData[module]) {
      cards.push(cardData[module]);
    }
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      columns={3}
    >
      {cards.map((cardDetails, index) => (
        <ModuleCard key={index} {...cardDetails} />
      ))}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    modules: state.auth.allowed_modules,
  };
};

export default connect(mapStateToProps)(ModuleCards);
