import { NavLink } from "react-router-dom";

import { Drawer, IconButton } from "@mui/material";

import SidebarListItems from "./SidebarListItems/SidebarListItems";
import Button from "../UI/Button/Button";
import useStyles from "./Sidebar_styles";
import { Logo_Nav as Logo } from "../Logo/Logo";

const Sidebar = (props) => {
  const { open, nav_items } = props;

  const classes = useStyles();

  const LogoButton = (
    <IconButton
      edge="start"
      className={classes.logoIcon}
      color="inherit"
      aria-label="menu"
      // onClick={}
    >
      <Logo logo={classes.logo} />
    </IconButton>
  );

  let LogoutButton = null;
  if (props.isAuth) {
    LogoutButton = (
      <NavLink
        style={{
          textDecoration: "inherit",
          color: "inherit",
          // borderBottom: "1px solid #B0B0B0",
        }}
        to="/"
      >
        <Button color="navbarBtn" onClick={props.onLogout}>
          LOGOUT
        </Button>
      </NavLink>
    );
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>{LogoButton}</div>
      <SidebarListItems
        options={nav_items}
        depth={2}
        spacing={8}
        closeSideDrawer={props.closeDrawer}
      />
      {LogoutButton}
    </Drawer>
  );
};

export default Sidebar;
