import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: [],
};

const setInitialDrawerData = (state, action) => {
  return {
    ...state,
    data: action.payload,
  };
};

const updateDrawerData = (state, action) => {
  const exclude = new Set(action.payload.excludeIds);
  const result = state.data.filter((obj) => !exclude.has(obj.id));
  return {
    ...state,
    data: result,
  };
};

const addDrawerData = (state, action) => {
  return {
    ...state,
    data: [...state.data, ...action.newData],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_DRAWER_DATA:
      return setInitialDrawerData(state, action);
    case actionTypes.UPDATE_DRAWER_DATA:
      return updateDrawerData(state, action);
    case actionTypes.ADD_DRAWER_DATA:
      return addDrawerData(state, action);
    default:
      return state;
  }
};

export default reducer;
