import { useState } from "react";

import NavbarDropdown from "./NavbarDropdown/NavbarDropdown";
import Button from "../../UI/Button/Button";

const NavbarItem = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  return (
    <Button
      disableRipple
      onMouseEnter={(e) => {
        setShowDropdown(true);
        setAnchorRef(e.currentTarget);
      }}
      onMouseLeave={(e) => {
        setShowDropdown(false);
        setAnchorRef(null);
      }}
      color="navbarBtn"
      style={
        showDropdown
          ? {
              textDecoration: "none",
              borderBottom: "4px solid #00adef",
              minWidth: "100px",
            }
          : { minWidth: "100px" }
      }
    >
      {props.children}
      <NavbarDropdown
        open={showDropdown}
        drop_options={props.item_suboptions}
        anchorEl={anchorRef}
      />
    </Button>
  );
};

export default NavbarItem;
