import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import { Backdrop, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import * as actions from "../../store/actions/index";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#808080",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Layout = (props) => {
  const location = useLocation();
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Navbar
        isAuth={props.isAuthenticated}
        onLogout={() => props.logout(props.client_id)}
        openDrawer={() => setOpenDrawer(true)}
        nav_items={props.nav_items}
      />
      <Sidebar
        isAuth={props.isAuthenticated}
        onLogout={() => props.logout(props.client_id)}
        open={openDrawer}
        closeDrawer={() => setOpenDrawer(false)}
        nav_items={props.nav_items}
      />
      <Backdrop
        open={openDrawer}
        onClick={() => setOpenDrawer(false)}
        className={classes.backdrop}
      />
      {props.isAuthenticated &&
      location.pathname !== "/home" &&
      location.pathname !== "/not-found" ? (
        <>
          <Container maxWidth="xl" style={{ marginTop: 5 }}>
            <Breadcrumbs />
          </Container>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.access_token != null,
    client_id: state.auth.client_id,
    nav_items: state.home.nav_items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (client_id) => dispatch(actions.logout(client_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
