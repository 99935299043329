import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { TextField as MuiTextField } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorPicker = ({
  size = "small",
  variant = "outlined",
  name,
  ...props
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const { control } = useFormContext();

  const colorConvert = {
    rgba: (c) => `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
    rgb: (c) => `rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b})`,
    hex: (c) => c.hex,

    rgba_rgb: (c) =>
      c.rgb.a === 1 ? colorConvert.rgb(c) : colorConvert.rgba(c),
    rgba_hex: (c) =>
      c.rgb.a === 1 ? colorConvert.hex(c) : colorConvert.rgba(c),
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <MuiTextField
              {...props}
              {...field}
              onClick={() => setShowPicker(true)}
              error={!!fieldState.error}
              helperText={fieldState.error && fieldState.error.message}
              size={size}
              variant={variant}
              autoComplete="off"
              InputProps={{
                style: { backgroundColor: field.value },
              }}
            />
            {showPicker && (
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", zIndex: "2" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => {
                      setShowPicker(false);
                    }}
                  />
                  <ChromePicker
                    color={field.value}
                    onChange={(data) => {
                      field.onChange(colorConvert["rgba_hex"](data));
                    }}
                  />
                </div>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default ColorPicker;
