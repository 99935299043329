import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorModal = (props) => {
  let error_title = props.errorTitle ? props.errorTitle : "Error";
  let error_description = props.errorDescription
    ? props.errorDescription
    : "Some Error has been encountered";

  if (props.error) {
    error_title = `HTTP Error ${props.error.status}: `;
    if (Array.isArray(props.error.data.error))
      error_title += props.error.data.error[0]
        .split("_")
        .map((i) => i.substring(0, 1).toUpperCase() + i.substring(1))
        .join(" ");
    else
      error_title += props.error.data.error
        .split("_")
        .map((i) => i.substring(0, 1).toUpperCase() + i.substring(1))
        .join(" ");
    if (Array.isArray(props.error.data.error_description))
      error_description = props.error.data.error_description[0];
    else error_description = props.error.data.error_description;
  }

  return (
    <div>
      <Dialog
        open={props.showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.closeModal}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <DialogTitle id="alert-modal-title">{error_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-modal-description">
            {error_description}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorModal;
