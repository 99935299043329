import { useFormContext, Controller } from "react-hook-form";
import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
} from "@mui/material";

const Autocomplete = ({
  textFieldProps,
  fieldToSave,
  fieldToShow,
  name,
  ...props
}) => {
  const { control } = useFormContext();

  const tranformValue = (value, fieldtosave) =>
    Array.isArray(value)
      ? value.map((v) => v[fieldtosave] || v)
      : value[fieldtosave];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiAutocomplete
          {...props}
          {...field}
          autoSelect={true}
          getOptionLabel={(item) => {
            if (props.options.length === 0) return "";
            return typeof item === "string" || typeof item === "number"
              ? props.options.find((i) => i[fieldToSave] === item)[fieldToShow]
              : item[fieldToShow];
          }}
          isOptionEqualToValue={(item, current) => {
            return item[fieldToSave] === current;
          }}
          onChange={(_, value) =>
            field.onChange(value ? tranformValue(value, fieldToSave) : null)
          }
          renderInput={(params) => {
            return (
              <MuiTextField
                {...params}
                {...textFieldProps}
                error={!!fieldState.error}
                helperText={fieldState.error && fieldState.error.message}
                autoComplete="off"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            );
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
