import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import { Container } from "@mui/material";

import axios from "../../../axiosInstance";
import Form from "../../../components/UI/Form/Form";
import withErrorHandler from "../../../hoc/withErrorHandler";
import * as actions from "../../../store/actions/index";

const ResetPasswordConfirm = ({ user_id, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryParams = {};
  for (let param of query.entries()) {
    queryParams[param[0]] = param[1];
  }

  const fieldMap = {
    new_password: {
      inputType: "TextField",
      label: "New Password",
      textFieldProps: {
        type: "password",
        autoComplete: "current-password",
      },
    },
    repeat_new_password: {
      inputType: "TextField",
      label: "Repeat New Password",
      textFieldProps: {
        type: "password",
        autoComplete: "current-password",
      },
    },
  };

  const initialValues = {
    new_password: "",
    repeat_new_password: "",
  };

  const validationSchema = Yup.object({
    new_password: Yup.string().required("Required"),
    repeat_new_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new_password"), null], "Passwords don't match!"),
  });

  const submitHandler = async (values) => {
    const newValues = cloneDeep(values);
    newValues.token = queryParams.token;
    const url = `/oauth2/reset_password/confirm/`;
    await axios
      .post(url, newValues)
      .then((response) => {
        history.push("/");
      })
      .catch((error) => {
        props.onAuthFail(error.response);
      });
  };

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: 15, marginBottom: 25, minHeight: "85vh" }}
    >
      <Form
        fieldMap={fieldMap}
        url="/oauth2/reset_password_confirm"
        initialValues={initialValues}
        validationSchema={validationSchema}
        customSubmitHandler={submitHandler}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthFail: (error) => dispatch(actions.authFail(error)),
    onClearError: () => dispatch(actions.authClearError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(ResetPasswordConfirm));
