import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Button = (props) => {
  const useStyles = makeStyles((theme) => {
    let style = null;

    if (
      !props.color ||
      props.color === "primary" ||
      props.color === "secondary"
    )
      return null;

    if (props.variant === "contained") {
      style = {
        button: {
          color: theme.palette[props.color].contrastText,
          backgroundColor: theme.palette[props.color].main,
          "&:hover": {
            backgroundColor: theme.palette[props.color].dark,
          },
        },
      };
    } else if (props.variant === "outlined") {
      style = {
        button: {
          color: theme.palette[props.color].main,
          borderColor: theme.palette[props.color].main,
          "&:hover": {
            backgroundColor: theme.palette[props.color].light,
          },
        },
      };
    } else {
      style = {
        button: {
          color: theme.palette[props.color].main,
          "&:hover": {
            backgroundColor: theme.palette[props.color].light,
          },
        },
      };
    }
    return style;
  });
  let classes = useStyles();
  let newProps = {};
  if (props.color !== "primary" && props.color !== "secondary") {
    for (let i in props) {
      if (i !== "color" && i !== "className") newProps[i] = props[i];
    }
  } else newProps = { ...props };

  return (
    <MuiButton
      className={
        props.className
          ? classes.button + " " + props.className
          : classes.button
      }
      {...newProps}
    >
      {newProps.children}
    </MuiButton>
  );
};

export default Button;
