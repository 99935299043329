import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TextField as MuiTextField } from "@mui/material";
import MuiDatePicker from "@mui/lab/DatePicker";

import {
  jsDateToLocalISO8601DateString,
  dateStringToLocalDate,
} from "../../../utility";

const DatePicker = forwardRef(({ name, textFieldProps, ...props }, ref) => {
  const { control, getValues } = useFormContext();

  const handleChange = (date) => {
    return date && date.valueOf() ? jsDateToLocalISO8601DateString(date) : null;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiDatePicker
          {...props}
          {...field}
          inputRef={ref}
          value={dateStringToLocalDate(getValues(name))}
          onChange={(date) => field.onChange(handleChange(date))}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              {...textFieldProps}
              autoComplete="off"
              error={!!fieldState.error}
              helperText={fieldState.error && fieldState.error.message}
            />
          )}
        />
      )}
    />
  );
});

export default DatePicker;
