import { useHistory } from "react-router-dom";

import { AppBar, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Logo_Nav as Logo } from "../Logo/Logo";
import NavbarItems from "./NavbarItems/NavbarItems";
import NavbarItemUserInfo from "./NavbarItems/NavbarItemUserInfo";
import IconButton from "../UI/Button/IconButton";
import useStyles from "./Navbar_styles.js";

const Navbar = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const LogoButton = (
    <IconButton
      edge="start"
      className={classes.logoIcon}
      color="navbarBtn"
      aria-label="menu"
      onClick={() => {
        history.push("/home");
      }}
    >
      <Logo logo={classes.logo} />
    </IconButton>
  );

  const MenuButton = (
    <IconButton
      edge="start"
      className={classes.logoIcon}
      color="navbarBtn"
      aria-label="menu"
      onClick={props.openDrawer}
    >
      <MenuIcon className={classes.menuIcon} />
    </IconButton>
  );

  return (
    <>
      <AppBar className={classes.root} position="fixed">
        <Toolbar className={classes.toolbar}>
          {LogoButton}
          {MenuButton}
          <div className={classes.title}>
            <NavbarItems nav_items={props.nav_items} />
          </div>
          <NavbarItemUserInfo
            isAuth={props.isAuth}
            logoutHandler={props.onLogout}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

export default Navbar;
