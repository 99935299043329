import { CircularProgress, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Spinner = ({ variant, ...props }) => {
  const classes = useStyles();

  let determinateComponents = null;
  if (variant === "determinate") {
    determinateComponents = (
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress {...props} />
        {determinateComponents}
      </Box>
    </div>
  );
};

export default Spinner;
