import { makeStyles } from "@mui/styles";

import NavbarItem from "./NavbarItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "80px",
  },
}));

const NavbarItems = (props) => {
  const classes = useStyles();

  const NavMenuItems = props.nav_items.map((nav_item, index) => {
    if (nav_item.show) {
      return (
        <NavbarItem key={index} item_suboptions={nav_item.item_suboptions}>
          {nav_item.name}
        </NavbarItem>
      );
    } else return null;
  });

  return <div className={classes.root}>{NavMenuItems}</div>;
};

export default NavbarItems;
