import * as actionTypes from "./actionTypes";
import axios from "axios";
import axiosSIPS from "../../axiosInstance";

export const getChatHistory = (access_token, user_id) => {
  return (dispatch) => {
    const url = "/chatbot/chat_history/";
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params: {
        user_mast: user_id,
      },
    };
    axiosSIPS
      .get(url, axiosOptions)
      .then((response) => {
        dispatch(setUserData(response.data));
        dispatch(setChatHistory(response.data));
      })
      .catch((error) => {
        dispatch(chatHistoryFail(error.response));
      });
  };
};

export const setUserData = (data) => {
  let userData = null;
  if (data.length > 0) {
    userData = data[0].user_mast;
  }
  return {
    type: actionTypes.SET_USER_DATA,
    userData: userData,
  };
};

export const setChatHistory = (data) => {
  const messages = [];
  data.forEach((i) => {
    const user_msg = {
      id: 0,
      message: i.user_message,
      senderName: i.user_mast,
      createdAt: i.created_at,
    };
    const bot_msg = {
      id: 1,
      message: i.bot_message,
      senderName: "Bot",
      createdAt: i.created_at,
    };
    messages.push(user_msg);
    messages.push(bot_msg);
  });
  return {
    type: actionTypes.SET_CHAT_HISTORY,
    messages: messages,
  };
};

export const chatHistoryFail = (err) => {
  return {
    type: actionTypes.CHAT_HISTORY_FAIL,
    error: err,
  };
};

export const getNewMessage = (access_token, user_id, user_message) => {
  return (dispatch) => {
    dispatch(setUserMessage(user_id, user_message));
    const urlChatBot =
      "https://chatbot.sarafcorporation.com/webhooks/rest/webhook";
    const bodyChatBot = {
      sender: user_id,
      message: user_message,
    };

    axios
      .post(urlChatBot, bodyChatBot)
      .then((responseChatBot) => {
        const urlSIPS = "/chatbot/chat_history/";
        const axiosOptionsSIPS = {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        };
        const bodySIPS = {
          user_mast: user_id,
          user_message: user_message,
          bot_message: responseChatBot.data[0].text,
        };
        axiosSIPS
          .post(urlSIPS, bodySIPS, axiosOptionsSIPS)
          .then((responseSIPS) => {
            dispatch(setBotMessage(responseSIPS.data));
          })
          .catch((errorSIPS) => {
            console.log(errorSIPS);
          });
      })
      .catch((errorChatBot) => {
        console.log(errorChatBot);
      });
  };
};

export const setUserMessage = (user_id, user_message) => {
  const message = {
    id: 0,
    message: user_message,
    senderName: user_id,
    createdAt: new Date().toISOString(),
  };
  return {
    type: actionTypes.SET_USER_MESSAGE,
    message: message,
  };
};

export const setBotMessage = (data) => {
  const message = {
    id: 1,
    message: data.bot_message,
    senderName: "Bot",
    createdAt: data.createdAt,
  };
  return {
    type: actionTypes.SET_BOT_MESSAGE,
    message: message,
  };
};

export const getConversationList = (access_token) => {
  return (dispatch) => {
    const url = "/chatbot/chat_history/";
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
      params: {
        conversation_list: true,
      },
    };

    axiosSIPS
      .get(url, axiosOptions)
      .then((response) => {
        dispatch(setConversationList(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setConversationList = (data) => {
  return {
    type: actionTypes.SET_CONVERSATION_LIST,
    data: data,
  };
};

export const setSelectedConversation = (conversation) => {
  return {
    type: actionTypes.SET_SELECTED_CONVERSATION,
    conversation: conversation,
  };
};
