import { Popper } from "@mui/material";
import NavbarDropList from "./NavbarDropList";

const NavbarDropdown = (props) => {
  return props.drop_options ? (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={props.sideMenu ? "right-start" : "bottom-start"}
      style={{
        zIndex: 5000,
        paddingRight: "50px",
        paddingBottom: "50px",
      }}
    >
      <NavbarDropList drop_options={props.drop_options} />
    </Popper>
  ) : null;
};

export default NavbarDropdown;
