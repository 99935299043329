import { List } from "@mui/material";
import SidebarListItem from "./SidebarListItem";

const SidebarListItems = (props) => {
  const callmylist = props.options.map((item) => (
    <SidebarListItem
      item={item}
      depth={props.depth}
      spacing={props.spacing}
      key={item.name}
      closeSideDrawer={props.closeSideDrawer}
    />
  ));

  return (
    <List disablePadding dense>
      {callmylist}
    </List>
  );
};

export default SidebarListItems;
