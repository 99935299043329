import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userData: null,
  messages: [],
  error: null,
  isTyping: false,
  conversationList: [],
  selectedConversation: null,
};

const setUserData = (state, action) => {
  return {
    ...state,
    userData: action.userData,
  };
};

const setChatHistory = (state, action) => {
  return {
    ...state,
    messages: action.messages,
  };
};

const chatHistoryFail = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const setUserMessage = (state, action) => {
  return {
    ...state,
    isTyping: true,
    messages: [...state.messages, action.message],
  };
};

const setBotMessage = (state, action) => {
  return {
    ...state,
    isTyping: false,
    messages: [...state.messages, action.message],
  };
};

const setConversationList = (state, action) => {
  return {
    ...state,
    conversationList: action.data,
  };
};

const setSelectedConversation = (state, action) => {
  return {
    ...state,
    selectedConversation: action.conversation,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA:
      return setUserData(state, action);
    case actionTypes.SET_CHAT_HISTORY:
      return setChatHistory(state, action);
    case actionTypes.CHAT_HISTORY_FAIL:
      return chatHistoryFail(state, action);
    case actionTypes.SET_USER_MESSAGE:
      return setUserMessage(state, action);
    case actionTypes.SET_BOT_MESSAGE:
      return setBotMessage(state, action);
    case actionTypes.SET_CONVERSATION_LIST:
      return setConversationList(state, action);
    case actionTypes.SET_SELECTED_CONVERSATION:
      return setSelectedConversation(state, action);
    default:
      return state;
  }
};

export default reducer;
