import { Collapse } from "@mui/material";
import SidebarListItems from "./SidebarListItems";

const CollapseableList = (props) => {
  const customRGBA = 20 + 3 ** (props.depth / 2 - 1);
  const color = "hsl(212,94%, " + customRGBA + "%)";

  return (
    <Collapse in={props.open} unmountOnExit style={{ backgroundColor: color }}>
      <SidebarListItems
        options={props.options}
        depth={props.depth}
        spacing={props.spacing}
        closeSideDrawer={props.closeSideDrawer}
      />
    </Collapse>
  );
};

export default CollapseableList;
