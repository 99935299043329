import { List, ListItemText, Paper } from "@mui/material";
import NavbarDropListItem from "./NavbarDropListItem";

const NavbarDropList = (props) => {
  return (
    <Paper>
      <List>
        {props.drop_options.map((dropdown_item, index) => {
          if (dropdown_item.show) {
            return (
              <NavbarDropListItem
                key={index}
                drop_suboptions={dropdown_item.drop_suboptions}
                to={dropdown_item.to}
                handler={dropdown_item.handler}
                startIcon={dropdown_item.startIcon}
              >
                <ListItemText primary={dropdown_item.name} />
              </NavbarDropListItem>
            );
          } else return null;
        })}
      </List>
    </Paper>
  );
};

export default NavbarDropList;
