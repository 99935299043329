import { useState } from "react";
import { NavLink } from "react-router-dom";

import { ListItem, ListItemText } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import CollapseableList from "./CollapseableList";

const SidebarListItem = (props) => {
  const [openSubList, setopenSubList] = useState(false);

  let expansionIcons = null;
  if (openSubList) {
    expansionIcons = <ExpandMore />;
  } else {
    expansionIcons = <ChevronRightIcon />;
  }

  let sidebarListItem = (
    <ListItem
      button
      onClick={() => setopenSubList(!openSubList)}
      style={{
        paddingLeft: props.depth * props.spacing,
        borderLeft: openSubList ? "4px solid #00adef" : null,
        // borderBottom: "1px solid #A9A9A9",
      }}
    >
      <ListItemText primary={props.item.name} />
      {expansionIcons}
    </ListItem>
  );
  if (!props.item.drop_suboptions && !props.item.item_suboptions) {
    sidebarListItem = (
      <NavLink
        to={props.item.to}
        style={{ textDecoration: "inherit", color: "inherit" }}
      >
        <ListItem
          button
          onClick={props.closeSideDrawer}
          style={{
            paddingLeft: props.depth * props.spacing,
            // borderBottom: "1px solid #808080",
          }}
        >
          <ListItemText primary={props.item.name} />
        </ListItem>
      </NavLink>
    );
  }

  if (props.item.show) {
    return (
      <>
        {sidebarListItem}
        {props.item.item_suboptions ? (
          <CollapseableList
            open={openSubList}
            options={props.item.item_suboptions}
            depth={props.depth + 2}
            spacing={props.spacing}
            closeSideDrawer={props.closeSideDrawer}
          />
        ) : props.item.drop_suboptions ? (
          <CollapseableList
            open={openSubList}
            options={props.item.drop_suboptions}
            depth={props.depth + 2}
            spacing={props.spacing}
            closeSideDrawer={props.closeSideDrawer}
          />
        ) : null}
      </>
    );
  } else return null;
};

export default SidebarListItem;
