import * as actionTypes from "./actionTypes";
import axios from "../../axiosInstance";

export const setFormSupplementaryData = (payload) => {
  return {
    type: actionTypes.SET_FORM_SUPPLEMENTARY_DATA,
    payload: payload,
  };
};

export const updateChainedData = (
  app,
  formName,
  chainFieldParentMaster,
  chainFieldParentValue,
  updateChainedField,
  chainFieldChildMaster,
  access_token,
  chainFieldMultipleChildPrevValue,
  chainFieldChildName,
  setValue
) => {
  return async (dispatch) => {
    const baseurl = "/" + app + "/" + chainFieldParentMaster + "/";
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };
    if (Array.isArray(chainFieldParentValue)) {
      let multipleChainedData = [];
      let chainFieldParentValueCount = 0;
      chainFieldParentValue.forEach((item) => {
        if (item) {
          const url = baseurl + item.toString() + "/";
          axios
            .get(url, axiosOptions)
            .then((response) => {
              multipleChainedData =
                response.data[chainFieldChildMaster].concat(
                  multipleChainedData
                );
              chainFieldParentValueCount += 1;
              if (chainFieldParentValueCount === chainFieldParentValue.length) {
                ///////// Dispatch data in setvalues for childfield in multiple  ////////
                let updatedChildData = [];
                multipleChainedData.forEach((item) => {
                  const key = chainFieldChildMaster + "_id";
                  if (chainFieldMultipleChildPrevValue?.includes(item[key]))
                    updatedChildData.push(item[key]);
                });
                if (updatedChildData !== [] && chainFieldMultipleChildPrevValue)
                  setValue(chainFieldChildName, updatedChildData);
                /////// END of block for childField update //////////////////////////////
                dispatch(
                  updateChainedDataDone(
                    formName,
                    updateChainedField,
                    multipleChainedData
                  )
                );
                dispatch(setDataReadyAfterUpdate(formName, updateChainedField));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    } else {
      if (chainFieldParentValue) {
        const url = baseurl + chainFieldParentValue.toString() + "/";
        axios
          .get(url, axiosOptions)
          .then((response) => {
            dispatch(
              updateChainedDataDone(
                formName,
                updateChainedField,
                response.data[chainFieldChildMaster]
              )
            );
            dispatch(setDataReadyAfterUpdate(formName, updateChainedField));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
};

export const setDataReadyAfterUpdate = (formName, field) => {
  return {
    type: actionTypes.SET_DATA_READY_AFTER_UPDATE,
    field: field,
    formName: formName,
  };
};

export const updateChainedDataDone = (formName, field, data) => {
  return {
    type: actionTypes.UPDATE_CHAINED_DATA_DONE,
    formName: formName,
    field: field,
    data: data,
  };
};

export const resetDataReadyAfterUpdate = (payload) => {
  let resetChainedDataFields = {};
  let resetUpdateReadyFields = {};
  payload["fields"].forEach((field) => {
    resetChainedDataFields[field] = [];
    resetUpdateReadyFields[field] = false;
  });
  return {
    type: actionTypes.RESET_DATA_READY_AFTER_UPDATE,
    chainedFields: resetChainedDataFields,
    resetFields: resetUpdateReadyFields,
    formName: payload.formName,
  };
};
