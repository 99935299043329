import * as actionTypes from "../actions/actionTypes";

const initialState = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  access_token: null,
  username: null,
  user_id: null,
  userData: null,
  error: null,
  loading: false,
  allowed_modules: null,
  permissions: null,
  auto_login: false,
  auth_redirect_path: "/home",
};

const authStart = (state) => {
  return {
    ...state,
    loading: true,
    error: null,
    auto_login: false,
  };
};

const authStartAutoLogin = (state) => {
  return {
    ...state,
    loading: true,
    error: null,
    auto_login: true,
  };
};

const authSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    username: action.username,
    user_id: action.user_id,
    access_token: action.access_token,
    error: null,
    auto_login: false,
  };
};

const authSetUserData = (state, action) => {
  return {
    ...state,
    userData: action.userData,
  };
};

const authSetPermission = (state, action) => {
  return {
    ...state,
    allowed_modules: action.allowed_modules,
    permissions: action.permissions,
  };
};

const authFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
    auto_login: false,
  };
};

const authClearError = (state) => {
  return {
    ...state,
    error: null,
  };
};

const authLogoutStart = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const authLogout = (state) => {
  return {
    ...state,
    username: null,
    user_id: null,
    access_token: null,
    allowed_modules: null,
    permissions: null,
    loading: false,
    auto_login: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_START_AUTO_LOGIN:
      return authStartAutoLogin(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_SET_USER_DATA:
      return authSetUserData(state, action);
    case actionTypes.AUTH_SET_PERMISSIONS:
      return authSetPermission(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_CLEAR_ERROR:
      return authClearError(state);
    case actionTypes.AUTH_LOGOUT_START:
      return authLogoutStart(state);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    default:
      return state;
  }
};

export default reducer;
