import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Typography, Grid, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Button from "../UI/Button/Button";
import Spinner from "../UI/Loading/Spinner";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontSize: "12rem",
    fontWeight: 450,

    [theme.breakpoints.up("sm")]: {
      fontSize: "15rem",
      fontWeight: 450,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18rem",
      fontWeight: 450,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20rem",
      fontWeight: 450,
    },
  },

  paragraphText: {
    fontSize: "1.5rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",
    },
  },
  page: {
    height: "calc(100vh - 84px)",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 64px)",
    },
  },
  button: {
    marginTop: 20,
    height: 80,
    width: 120,
  },
}));

const NotFound = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const redirectHandler = () => {
    if (props.isAuthenticated) {
      history.replace("/home");
    } else {
      history.replace("/");
    }
  };

  let page = (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Typography
            className={classes.headingText}
            variant="h1"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            404
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.paragraphText}
            variant="body1"
            component="h2"
            gutterBottom
          >
            The page that you are looking for was not found, click the button to
            go back to the home screen
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={redirectHandler}
            className={classes.button}
            variant="outlined"
            color="secondary"
          >
            Go Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  if (props.loading) {
    page = (
      <Grid
        className={classes.page}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Spinner size={50} />
        </Grid>
      </Grid>
    );
  }

  return page;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.access_token !== null,
    loading: state.auth.loading,
  };
};

export default connect(mapStateToProps)(NotFound);
