import logoMain from "../../assets/images/Logo/SCIPL_logo.png";
import logoWhite from "../../assets/images/Logo/logo_main_white.png";

export const Logo = (props) => {
  return <img src={logoMain} alt="logo" className={props.logo} />;
};

export const Logo_Nav = (props) => {
  return <img src={logoWhite} alt="logo" className={props.logo} />;
};
