import * as actionTypes from "./actionTypes";
import NavbarItemSetter from "../../components/Navbar/NavbarItemSetter";

export const setNavItems = (module_name, permissions) => {
  let navbar_items = [];
  navbar_items = NavbarItemSetter(module_name, permissions);

  return {
    type: actionTypes.SET_NAV_ITEMS,
    nav_items: navbar_items,
  };
};

export const clearNavItems = () => {
  return {
    type: actionTypes.CLEAR_NAV_ITEMS,
  };
};
