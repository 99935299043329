// AUTH ACTIONS
export const AUTH_START = "AUTH_START";
export const AUTH_START_AUTO_LOGIN = "AUTH_START_AUTO_LOGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_SET_PERMISSIONS = "AUTH_SET_PERMISSIONS";
export const AUTH_SET_USER_DATA = "AUTH_SET_USER_DATA";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CLEAR_ERROR = "AUTH_CLEAR_ERROR";
export const AUTH_SET_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

//HOME ACTIONS
export const SET_NAV_ITEMS = "SET_NAV_ITEMS";
export const CLEAR_NAV_ITEMS = "CLEAR_NAV_ITEMS";

//FORM DATA ACTIONS
export const SET_FORM_SUPPLEMENTARY_DATA = "SET_FORM_SUPPLEMENTARY_DATA";
export const SET_DATA_READY_AFTER_UPDATE = "SET_DATA_READY_AFTER_UPDATE";
export const RESET_DATA_READY_AFTER_UPDATE = "RESET_DATA_READY_AFTER_UPDATE";
export const UPDATE_CHAINED_DATA_DONE = "UPDATE_CHAINED_DATA_DONE";

//DRAWER DATA ACTIONS
export const SET_INITIAL_DRAWER_DATA = "SET_INITIAL_DRAWER_DATA";
export const UPDATE_DRAWER_DATA = "UPDATE_DRAWER_DATA";
export const ADD_DRAWER_DATA = "ADD_DRAWER_DATA";

//CHATBOT ACTIONS
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const CHAT_HISTORY_FAIL = "CHAT_HISTORY_FAIL";
export const SET_USER_MESSAGE = "SET_USER_MESSAGE";
export const SET_BOT_MESSAGE = "SET_BOT_MESSAGE";
export const SET_CONVERSATION_LIST = "SET_CONVERSATION_LIST";
export const SET_SELECTED_CONVERSATION = "SET_SELECTED_CONVERSATION";
