import { useEffect } from "react";
import { connect } from "react-redux";

import { Paper } from "@mui/material";

import ModuleCards from "../ModuleCards/ModuleCards";
import background_img from "../../assets/images/Homepage/home_background.png";
import * as actions from "../../store/actions/index";

const styles = {
  backgroundImage: `url(${background_img})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  margin: 0,
};

const Home = (props) => {
  const { onClearNavItems } = props;

  useEffect(() => {
    onClearNavItems();
  }, [onClearNavItems]);

  return (
    <Paper style={styles} square>
      <ModuleCards />
    </Paper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return { onClearNavItems: () => dispatch(actions.clearNavItems()) };
};

export default connect(null, mapDispatchToProps)(Home);
