import { useEffect } from "react";

import Autocomplete from "./Autocomplete";

const ChainedAutocomplete = ({
  app,
  formName,
  chainFieldParentMaster,
  chainFieldParentValue,
  updateChainedField,
  chainFieldChildMaster,
  access_token,
  updateChainedAction,
  chainFieldChildName,
  chainFieldPrevParentValue,
  chainFieldChildMultiple,
  chainFieldChildValue,
  chainFieldPrevChildValue,
  setValue,
  ...props
}) => {
  useEffect(() => {
    if (chainFieldPrevParentValue.current !== chainFieldParentValue) {
      let chainFieldMultipleChildPrevValue;
      if (chainFieldPrevParentValue.current) {
        if (chainFieldChildMultiple && chainFieldChildValue !== "undefined") {
          if (chainFieldChildValue.length > 0) {
            chainFieldPrevChildValue.current = chainFieldChildValue;
            setValue(chainFieldChildName, []);
          }
        } else setValue(chainFieldChildName, null);
      }
      if (chainFieldParentValue !== [] && chainFieldParentValue !== null) {
        if (chainFieldPrevChildValue?.current)
          chainFieldMultipleChildPrevValue = chainFieldPrevChildValue.current;
        updateChainedAction(
          app,
          formName,
          chainFieldParentMaster,
          chainFieldParentValue,
          updateChainedField,
          chainFieldChildMaster,
          access_token,
          chainFieldMultipleChildPrevValue,
          chainFieldChildName,
          setValue
        );
      }
      chainFieldPrevParentValue.current = chainFieldParentValue;
    }
  }, [
    app,
    formName,
    chainFieldParentMaster,
    chainFieldChildName,
    chainFieldChildMaster,
    chainFieldParentValue,
    updateChainedField,
    updateChainedAction,
    access_token,
    setValue,
    chainFieldPrevParentValue,
    chainFieldChildMultiple,
    chainFieldChildValue,
    chainFieldPrevChildValue,
  ]);
  return <Autocomplete {...props} />;
};

export default ChainedAutocomplete;
