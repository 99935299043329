import { parse } from "date-fns";

export class DefaultDict {
  constructor(key, defaultVal) {
    return new Proxy(key, {
      get: (target, name) =>
        name in target
          ? target[name]
          : (target[name] =
              typeof defaultVal === "function"
                ? new defaultVal().valueOf()
                : defaultVal),
    });
  }
}

export const jsDateToLocalISO8601DateString = (date) => {
  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join("-");
};

export const dateStringToLocalDate = (s) => {
  if (!s) return null;
  return parse(s, "yyyy-MM-dd", new Date());
};
