import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#0a3664",
      light: "#e5e9ef",
      dark: "#05264c",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9a0036",
      light: "#fbe2e6",
      dark: "#6b0025",
      contrastText: "#fff",
    },
    errorBtn: {
      main: "#e32361",
      light: "#fbe4eb",
      dark: "#9e1843",
      contrastText: "#fff",
    },
    warningBtn: {
      main: "#f57c00",
      light: "#fff3e0",
      dark: "#ab5600",
      contrastText: "#fff",
    },
    infoBtn: {
      main: "#1976d2",
      light: "#e3f2fd",
      dark: "#115293",
      contrastText: "#fff",
    },
    successBtn: {
      main: "#2ba3a5",
      light: "#e1f1f3",
      dark: "#1e7273",
      contrastText: "#fff",
    },
    navbarBtn: {
      main: "#fcfdfe",
      light: "#05264c",
      dark: "#e5e9ef",
      contrastText: "#f57c00",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
  },
});

export default theme;
