import * as actionTypes from "./actionTypes";
import axios from "../../axiosInstance";

import { DefaultDict } from "../../utility";

let tokenTimer = null;

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, username, user_id) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    access_token: token,
    username: username,
    user_id: user_id,
  };
};

export const authGetUserData = (access_token, username, user_id) => {
  return (dispatch) => {
    const permission_url = "/oauth2/user_mast/" + user_id + "/";
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };
    axios
      .get(permission_url, axiosOptions)
      .then((response) => {
        const userData = { ...response.data.emp_mast };
        if (userData.employee_image) {
          userData.employee_image =
            process.env.REACT_APP_API_URL.substring(
              0,
              process.env.REACT_APP_API_URL.length - 1
            ) + userData.employee_image;
        }
        userData.client_mast = response.data.client_mast;
        userData.contract_mast = response.data.contract_mast;
        userData.installation_mast = response.data.installation_mast;
        dispatch(authSetUserData(userData));

        const permission_map = response.data.all_permissions_map;
        let modules = Array.from(new Set(Object.values(permission_map)));

        const permissions = {};
        modules.forEach((i) => {
          permissions[i] = new DefaultDict({}, Array);
        });

        Object.keys(permission_map).forEach((i) => {
          let [action, feature] = i.split(/_(.+)/);
          permissions[permission_map[i]][feature].push(action);
        });

        //IF NOT ALLOWED TO EDIT PERMISSIONS THEN DO NOT SHOW MODULE PERMISSION MANAGER
        if (!permission_map.change_user_mast) {
          modules = modules.filter((i) => i !== "oauth2");
        }
        //IF USER IS CLIENT THEN REMOVE SUPPLEMENTARY SETUP MODULES
        if (
          !!userData.client_mast ||
          !!userData.contract_mast ||
          !!userData.installation_mast
        ) {
          modules = modules.filter(
            (i) => i !== "oauth2" && i !== "company_setup"
          );
        }

        dispatch(authSetPermission(modules, permissions));

        dispatch(authSuccess(access_token, username, user_id));
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFail(err.response));
      });
  };
};

export const authSetUserData = (userData) => {
  return {
    type: actionTypes.AUTH_SET_USER_DATA,
    userData: userData,
  };
};

export const authSetPermission = (modules, permissions) => {
  return {
    type: actionTypes.AUTH_SET_PERMISSIONS,
    allowed_modules: modules,
    permissions: permissions,
  };
};

export const authFail = (err) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: err,
  };
};

export const authClearError = () => {
  return {
    type: actionTypes.AUTH_CLEAR_ERROR,
  };
};

export const auth = (username, password, client_id) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      username: username,
      password: password,
      client_id: client_id,
    };

    let login_url = "/oauth2/login/";

    axios
      .post(login_url, authData)
      .then((response) => {
        const expiry_time = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );

        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("username", username);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("expiry_time", expiry_time);

        dispatch(
          authGetUserData(
            response.data.access_token,
            username,
            response.data.user_id
          )
        );
        dispatch(setAuthTimeout(response.data.expires_in));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authRefreshToken = () => {
  return (dispatch, getState) => {
    const client_id = getState().auth.client_id;
    const username = localStorage.getItem("username");
    const user_id = localStorage.getItem("user_id");
    const isPermLoaded = getState().auth.permissions != null;

    const authRefreshData = {
      access_token: localStorage.getItem("access_token"),
      client_id: client_id,
    };

    let url = "/oauth2/refresh_token/";

    axios
      .post(url, authRefreshData)
      .then((response) => {
        const expiry_time = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("username", username);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("expiry_time", expiry_time);

        if (isPermLoaded)
          dispatch(authSuccess(response.data.access_token, username, user_id));
        else
          dispatch(
            authGetUserData(response.data.access_token, username, user_id)
          );
        dispatch(setAuthTimeout(response.data.expires_in));
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const logout = (client_id) => {
  return (dispatch) => {
    dispatch(authLogoutStart());
    const access_token = localStorage.getItem("access_token");

    const authLogoutData = {
      access_token: access_token,
      client_id: client_id,
    };

    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    };

    let url = "/oauth2/logout/";

    axios
      .post(url, authLogoutData, axiosOptions)
      .then((response) => {
        dispatch(authLogout());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const authLogoutStart = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_START,
  };
};

export const authLogout = () => {
  if (!!tokenTimer) {
    clearTimeout(tokenTimer);
  }
  localStorage.removeItem("access_token");
  localStorage.removeItem("username");
  localStorage.removeItem("user_id");
  localStorage.removeItem("expiry_time");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const setAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    tokenTimer = setTimeout(() => {
      dispatch(authRefreshToken());
    }, expirationTime * 1000);
  };
};

export const authAutoLogin = () => {
  return {
    type: actionTypes.AUTH_START_AUTO_LOGIN,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    dispatch(authAutoLogin());
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      dispatch(authLogout());
    } else {
      const expiry_time = new Date(localStorage.getItem("expiry_time"));
      if (expiry_time <= new Date()) {
        dispatch(authRefreshToken());
      } else {
        const username = localStorage.getItem("username");
        const user_id = localStorage.getItem("user_id");
        dispatch(authGetUserData(access_token, username, user_id));
        dispatch(
          setAuthTimeout((expiry_time.getTime() - new Date().getTime()) / 1000)
        );
      }
    }
  };
};
