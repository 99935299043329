import * as actionTypes from "../actions/actionTypes";

const initialState = {
  nav_items: [],
};

const setNavItems = (state, action) => {
  return {
    ...state,
    nav_items: action.nav_items,
  };
};

const clearNavItems = (state) => {
  return {
    ...state,
    nav_items: [],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NAV_ITEMS:
      return setNavItems(state, action);
    case actionTypes.CLEAR_NAV_ITEMS:
      return clearNavItems(state);
    default:
      return state;
  }
};

export default reducer;
