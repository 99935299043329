import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container } from "@mui/material";

import axios from "../../../axiosInstance";
import Form from "../../../components/UI/Form/Form";
import withErrorHandler from "../../../hoc/withErrorHandler";
import * as actions from "../../../store/actions/index";

const ChangePassword = ({ user_id, ...props }) => {
  const history = useHistory();

  const fieldMap = {
    old_password: {
      inputType: "TextField",
      label: "Old Password",
      textFieldProps: {
        type: "password",
        autoComplete: "current-password",
      },
    },
    new_password: {
      inputType: "TextField",
      label: "New Password",
      textFieldProps: {
        type: "password",
        autoComplete: "current-password",
      },
    },
    repeat_new_password: {
      inputType: "TextField",
      label: "Repeat New Password",
      textFieldProps: {
        type: "password",
        autoComplete: "current-password",
      },
    },
  };

  const initialValues = {
    old_password: "",
    new_password: "",
    repeat_new_password: "",
  };

  const validationSchema = Yup.object({
    old_password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    repeat_new_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new_password"), null], "Passwords don't match!"),
  });

  const submitHandler = async (values) => {
    const url = `/oauth2/change_password/${user_id}/`;
    const axiosOptions = {
      headers: {
        Authorization: "Bearer " + props.access_token,
      },
    };
    await axios
      .put(url, values, axiosOptions)
      .then((response) => {
        props.onAuth(props.username, values["new_password"], props.client_id);
        history.push("/home");
      })
      .catch((error) => {
        props.onAuthFail(error.response);
      });
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: 15, marginBottom: 25, minHeight: "85vh" }}
    >
      <Form
        fieldMap={fieldMap}
        url="/oauth2/change_password"
        initialValues={initialValues}
        validationSchema={validationSchema}
        customSubmitHandler={submitHandler}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    user_id: state.auth.user_id,
    access_token: state.auth.access_token,
    error: state.auth.error,
    client_id: state.auth.client_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password, client_id) =>
      dispatch(actions.auth(username, password, client_id)),
    onClearError: () => dispatch(actions.authClearError()),
    onAuthFail: (error) => dispatch(actions.authFail(error)),
    onLogout: (client_id) => dispatch(actions.logout(client_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(ChangePassword));
