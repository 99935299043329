import { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { Input, FormHelperText, Typography, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";

import IconButton from "../Button/IconButton";

const ImageUploadBase64 = ({
  name,
  disabled = false,
  showText = false,
  ...props
}) => {
  const { control, formState } = useFormContext();

  const imgRef = useRef(null);

  const getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  };

  const handleChange = (event, field) => {
    const file = event.currentTarget.files[0];
    getBase64(file, (result) => {
      field.onChange(result);
    });
  };

  const handleImageUpload = () => {
    imgRef.current.click();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Input
              style={{ display: "none" }}
              {...props}
              inputProps={{
                type: "file",
                disabled: disabled || formState.isSubmitting,
                accept: "image/*",
              }}
              onChange={(event) => handleChange(event, field)}
              inputRef={imgRef}
            />
            {fieldState.error && (
              <FormHelperText error>{fieldState.error.message}</FormHelperText>
            )}
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  color="infoBtn"
                  component="span"
                  onClick={handleImageUpload}
                >
                  <CloudUploadIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  color="errorBtn"
                  component="span"
                  onClick={() => field.onChange(null)}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid>
                {showText && (
                  <Typography
                    variant="subtitle1"
                    sx={{ marginLeft: "3px" }}
                  >{`Upload ${props.label}`}</Typography>
                )}
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default ImageUploadBase64;
