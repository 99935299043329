import ErrorModal from "../components/UI/Modal/ErrorModal";

const withErrorHandler = (WrappedComponent) => {
  return (props) => {
    let modal = null;
    if (props.error) {
      modal = (
        <ErrorModal
          showModal={props.error ? true : false}
          closeModal={props.onClearError}
          error={props.error}
        />
      );
    }

    return (
      <>
        {modal}
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withErrorHandler;
