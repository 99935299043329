import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";

import Button from "../../../components/UI/Button/Button";
import useStyles from "./Login_styles.js";
import { Logo } from "../../../components/Logo/Logo";
import * as actions from "../../../store/actions/index";
import LinearLoader from "../../../components/UI/Loading/LinearLoader";
import withErrorHandler from "../../../hoc/withErrorHandler";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://sarafcorporation.com/">
        SCIPL
      </Link>{" "}
      {2006}
      {"."}
    </Typography>
  );
};

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const classes = useStyles();

  const { onClearNavItems } = props;

  useEffect(() => {
    onClearNavItems();
    return () => {
      history.push("/home");
    };
  }, [onClearNavItems, history]);

  const submitHandler = (event) => {
    event.preventDefault();
    props.onAuth(username, password, props.client_id);
  };

  // let auth_form =
  return (
    <Container className={classes.container} component="main" maxWidth="sm">
      <Box
        className={classes.loadingBox}
        width="100%"
        px={0.5}
        mt={10}
        height={4}
      >
        {props.loading ? (
          <LinearLoader
            color="secondary"
            style={{
              borderRadius: "20%",
            }}
          />
        ) : null}
      </Box>
      <Box
        component="div"
        mt={-0.625}
        px={7}
        py={5}
        pt={0}
        className={classes.paper}
        border={1}
        borderRadius="2%"
        borderColor="grey.400"
      >
        <Box component="div" pt={7} pb={5}>
          <Logo logo={classes.logo} />
        </Box>
        <Typography component="h1" variant="h5" mt={4}>
          Login
        </Typography>
        <form onSubmit={submitHandler} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(event) => setUsername(event.target.value)}
            value={username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href="/reset-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    client_id: state.auth.client_id,
    is_auto_login: state.auth.auto_login,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password, client_id) =>
      dispatch(actions.auth(username, password, client_id)),
    onClearError: () => dispatch(actions.authClearError()),
    onClearNavItems: () => dispatch(actions.clearNavItems()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(Login));
