import * as actionTypes from "../actions/actionTypes";

const initialState = {
  formSupplementaryData: null,
  formsChainedData: {
    //form name: {chainedData: {}, dataAfterUpdateReady:{}}
    empMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        desgnMast: false,
        roleMast: false,
        contractMast: false,
        installationMast: false,
        presentStateMast: false,
        presentCityMast: false,
        permanentStateMast: false,
        permanentCityMast: false,
      },
    },
    roleMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        desgnMast: false,
      },
    },
    desgnTrans: {
      chainedData: null,
      dataAfterUpdateReady: {
        desgnMast: false,
        roleMast: false,
      },
    },
    officeMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        stateMast: false,
        cityMast: false,
      },
    },
    warehouseMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        stateMast: false,
        cityMast: false,
      },
    },
    factoryMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        stateMast: false,
        cityMast: false,
      },
    },
    clientMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        stateMast: false,
        cityMast: false,
      },
    },
    installationMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        contractMast: false,
      },
    },
    invoiceMast: {
      chainedData: null,
      dataAfterUpdateReady: {
        contractMast: false,
        installationMast: false,
      },
    },
    blog: {
      chainedData: null,
      dataAfterUpdateReady: {
        contractMast: false,
        installationMast: false,
      },
    },
  },
};

const setFormSupplementaryData = (state, action) => {
  return {
    ...state,
    formSupplementaryData: {
      ...state.formSupplementaryData,
      ...action.payload,
    },
  };
};

const setDataReadyAfterUpdate = (state, action) => {
  return {
    ...state,
    formsChainedData: {
      ...state.formsChainedData,
      [action.formName]: {
        ...state.formsChainedData[action.formName],
        dataAfterUpdateReady: {
          ...state.formsChainedData[action.formName]["dataAfterUpdateReady"],
          [action.field]: true,
        },
      },
    },
  };
};

const resetDataReadyAfterUpdate = (state, action) => {
  return {
    ...state,
    formsChainedData: {
      ...state.formsChainedData,
      [action.formName]: {
        ...state.formsChainedData[action.formName],
        chainedData: {
          ...state.formsChainedData[action.formName]["chainedData"],
          ...action.chainedFields,
        },
        dataAfterUpdateReady: {
          ...state.formsChainedData[action.formName]["dataAfterUpdateReady"],
          ...action.resetFields,
        },
      },
    },
  };
};

const updateChainedDataDone = (state, action) => {
  return {
    ...state,
    formsChainedData: {
      ...state.formsChainedData,
      [action.formName]: {
        ...state.formsChainedData[action.formName],
        chainedData: {
          ...state.formsChainedData[action.formName]["chainedData"],
          [action.field]: action.data,
        },
      },
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORM_SUPPLEMENTARY_DATA:
      return setFormSupplementaryData(state, action);
    case actionTypes.SET_DATA_READY_AFTER_UPDATE:
      return setDataReadyAfterUpdate(state, action);
    case actionTypes.UPDATE_CHAINED_DATA_DONE:
      return updateChainedDataDone(state, action);
    case actionTypes.RESET_DATA_READY_AFTER_UPDATE:
      return resetDataReadyAfterUpdate(state, action);

    default:
      return state;
  }
};

export default reducer;
