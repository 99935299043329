import { useState } from "react";
import { connect } from "react-redux";

import { Avatar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LockResetIcon from "@mui/icons-material/LockReset";

import NavbarDropdown from "./NavbarDropdown/NavbarDropdown";
import Button from "../../UI/Button/Button";
import useStyles from "../Navbar_styles.js";

const NavbarItemUserInfo = (props) => {
  const classes = useStyles();

  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  const userActions = [
    {
      name: "Change Password",
      to: "/change-password",
      show: true,
      startIcon: <LockResetIcon />,
    },
    {
      name: "Logout",
      to: "/",
      show: true,
      handler: props.logoutHandler,
      startIcon: <LogoutIcon />,
    },
  ];

  let userInfoBtn = null;

  if (props.isAuth) {
    userInfoBtn = (
      <>
        <Button
          color="navbarBtn"
          startIcon={<Avatar src={props.userData?.employee_image} />}
          className={classes.userInfo}
          onClick={(e) => {
            setShowDropdown(true);
            setAnchorRef(e.currentTarget);
          }}
          onMouseLeave={(e) => {
            setShowDropdown(false);
            setAnchorRef(null);
          }}
        >
          <Typography variant="button">{props.username}</Typography>
          <NavbarDropdown
            open={showDropdown}
            drop_options={userActions}
            anchorEl={anchorRef}
          />
        </Button>{" "}
      </>
    );
  }

  return userInfoBtn;
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    username: state.auth.username,
  };
};

export default connect(mapStateToProps)(NavbarItemUserInfo);
