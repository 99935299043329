import { IconButton as MuiIconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const IconButton = (props) => {
  const useStyles = makeStyles((theme) => {
    let style = null;
    style = {
      button: {
        color: theme.palette[props.color].main,
        "&:hover": {
          backgroundColor: theme.palette[props.color].light,
        },
      },
    };
    return style;
  });

  let classes = useStyles();
  let newProps = {};
  for (let i in props) {
    if (i !== "color" && i !== "className") newProps[i] = props[i];
  }

  return (
    <MuiIconButton
      className={
        props.className
          ? classes.button + " " + props.className
          : classes.button
      }
      {...newProps}
    >
      {newProps.children}
    </MuiIconButton>
  );
};

export default IconButton;
