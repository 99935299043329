import { LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LinearIndeterminate(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress {...props} />
    </div>
  );
}
