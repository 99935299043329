import * as actionTypes from "./actionTypes";

export const setInitialDrawerData = (payload) => {
  return {
    type: actionTypes.SET_INITIAL_DRAWER_DATA,
    payload: payload,
  };
};

export const updateDrawerData = (payload) => {
  return {
    type: actionTypes.UPDATE_DRAWER_DATA,
    payload: payload,
  };
};

export const addDrawerData = (payload) => {
  return {
    type: actionTypes.ADD_DRAWER_DATA,
    newData: payload.newData,
  };
};
