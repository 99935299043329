import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.dark,
    height: 84,
    [theme.breakpoints.down("sm")]: {
      height: 64,
    },
  },
  logoIcon: {
    marginRight: theme.spacing(2),
    height: 80,
    [theme.breakpoints.down("sm")]: {
      height: 64,
    },
  },
  menuIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logo: {
    maxHeight: 60,
    borderRadius: 5,
    padding: 5,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  offset: {
    minHeight: 84,
    [theme.breakpoints.down("sm")]: {
      minHeight: 64,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "flex-start",
  },
  userInfo: {
    height: 56,
    minWidth: 130,
    marginTop: 12,
    marginLeft: 20,
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default useStyles;
