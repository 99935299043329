import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { breadCrumbsMap } from "./breadCrumbsMap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "5px 15px",
    // background: "hsla(211, 40%, 90%, 0.75)",
    background: "hsla(24, 20%, 95%, 0.9)",
  },
}));

const Breadcrumbs = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const pathnames = pathname.split("/").filter(isNaN); //filters out "" and all numbers
  return (
    <>
      <Paper variant="outlined" className={classes.paper}>
        <MUIBreadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {pathnames.length > 0 ? (
            <Link color="primary" onClick={() => history.push("/home")}>
              Home
            </Link>
          ) : (
            <Typography color="secondary"> Home </Typography>
          )}
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Typography color="secondary" key={name}>
                {breadCrumbsMap[name]}
              </Typography>
            ) : (
              <Link
                color="primary"
                key={name}
                onClick={() => history.push(routeTo)}
              >
                {breadCrumbsMap[name]}
              </Link>
            );
          })}
        </MUIBreadcrumbs>
      </Paper>
    </>
  );
};

export default Breadcrumbs;
