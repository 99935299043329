import { useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { Input, FormHelperText, Typography, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";

import IconButton from "../Button/IconButton";

const ImageUpload = ({
  name,
  disabled = false,
  showText = false,
  multiple = false,
  ...props
}) => {
  const imgRef = useRef(null);
  const { control, formState } = useFormContext();

  const handleChange = (event, field) => {
    field.onChange(event.target.files);
  };

  const handleImageUpload = () => {
    imgRef.current.click();
  };

  let uploadText = null;
  if (showText) {
    uploadText = (
      <Typography
        component="span"
        variant="subtitle1"
        sx={{ marginLeft: "3px" }}
      >{`Upload ${props.label}`}</Typography>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <>
            <Input
              style={{ display: "none" }}
              {...props}
              inputProps={{
                type: "file",
                disabled: disabled || formState.isSubmitting,
                accept: "image/*",
                multiple: multiple,
              }}
              onChange={(event) => handleChange(event, field)}
              inputRef={imgRef}
            />
            {fieldState.error && (
              <FormHelperText error>{fieldState.error.message}</FormHelperText>
            )}
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  color="infoBtn"
                  component="span"
                  onClick={handleImageUpload}
                >
                  <CloudUploadIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  color="errorBtn"
                  component="span"
                  onClick={() => field.onChange([])}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid>{uploadText}</Grid>
              <Grid>
                {field.value.length > 0 && (
                  <Typography
                    component="span"
                    variant="subtitle1"
                    sx={{ marginLeft: "3px" }}
                  >
                    {`(${field.value.length} files)`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        );
      }}
    />
  );
};

export default ImageUpload;
