import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import {
  Alert,
  AlertTitle,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import axios from "../../../axiosInstance";
import Form from "../../../components/UI/Form/Form";
import IconButton from "../../../components/UI/Button/IconButton";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    height: "28vh",
  },
  successAlert: {
    height: "45vh",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const fieldMap = {
    username: {
      inputType: "TextField",
      label: "Username",
    },
  };

  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Required"),
  });

  const submitHandler = async (values) => {
    const url = "/oauth2/reset_password/";
    axios
      .post(url, values)
      .then((response) => {
        if (response.data.status === "OK") {
          setError(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setError(error.response.data);
      });
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid
          className={success ? classes.successAlert : classes.errorAlert}
          container
          item
          direction="column"
          justifyContent="flex-end"
        >
          {error && (
            <Grid item sx={{ width: "100%" }}>
              <Alert severity="error">
                <AlertTitle>
                  {error.error
                    .split("_")
                    .map(
                      (i) => i.substring(0, 1).toUpperCase() + i.substring(1)
                    )
                    .join(" ")}
                </AlertTitle>
                {error.error_description}
              </Alert>
            </Grid>
          )}
          {success && (
            <Grid item sx={{ width: "100%" }}>
              <Alert severity="success">
                <Grid item container>
                  <Grid item>
                    <AlertTitle>Password Reset Email Sent</AlertTitle>
                  </Grid>
                  <Grid item>
                    The password reset email has been sent to the registered
                    Email ID. Please click on the link to change the password.
                  </Grid>
                  <Grid item container justifyContent="flex-end">
                    <IconButton
                      color="primary"
                      onClick={() => history.push("/")}
                    >
                      <HomeIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
          )}
        </Grid>
        {!success && (
          <Grid item>
            <Box
              component="div"
              sx={{ padding: "5%" }}
              border={1}
              borderRadius="2%"
              borderColor="grey.400"
            >
              <Typography
                component="div"
                variant="h6"
                sx={{ marginBottom: "4%" }}
              >
                Enter username to reset password. The Password reset link will
                be sent to the registered Email ID.
              </Typography>
              <Form
                fieldMap={fieldMap}
                url="/oauth2/reset_password/"
                initialValues={initialValues}
                validationSchema={validationSchema}
                customSubmitHandler={submitHandler}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default ResetPassword;
