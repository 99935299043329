import { useMemo, forwardRef, useState } from "react";
import { NavLink } from "react-router-dom";

import NavbarDropdown from "./NavbarDropdown";
import { ListItem, ListItemText, ListItemIcon } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const NavbarDropListItem = (props) => {
  const { drop_suboptions, to } = props;

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [anchorRef, setAnchorRef] = useState(null);

  //to do navigation in dropdown
  const routingSetter = useMemo(
    () =>
      forwardRef((linkProps, ref) => (
        <NavLink
          ref={ref}
          style={{ textDecoration: "inherit", color: "inherit" }}
          to={to}
          {...linkProps}
        />
      )),
    [to]
  );

  return (
    <ListItem
      button
      disableRipple
      onClick={props.handler ? props.handler : null}
      onMouseEnter={(e) => {
        setShowSubMenu(true);
        setAnchorRef(e.currentTarget);
      }}
      onMouseLeave={(e) => {
        setShowSubMenu(false);
        setAnchorRef(null);
      }}
      component={
        !(drop_suboptions && drop_suboptions.length > 0) ? routingSetter : null
      }
      dense={true}
    >
      {props.startIcon && <ListItemIcon>{props.startIcon}</ListItemIcon>}
      <ListItemText primary={props.children} />
      <NavbarDropdown
        drop_options={drop_suboptions}
        open={showSubMenu}
        anchorEl={anchorRef}
        sideMenu={true}
      />
      {drop_suboptions && drop_suboptions.length > 0 ? (
        <ChevronRightIcon
          color="inherit"
          style={{ verticalAlign: "middle", paddingLeft: "5px" }}
        />
      ) : null}
    </ListItem>
  );
};

export default NavbarDropListItem;
